exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-document-js": () => import("./../../../src/pages/document.js" /* webpackChunkName: "component---src-pages-document-js" */),
  "component---src-pages-faculties-js": () => import("./../../../src/pages/faculties.js" /* webpackChunkName: "component---src-pages-faculties-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-testimonials-js": () => import("./../../../src/pages/testimonials.js" /* webpackChunkName: "component---src-pages-testimonials-js" */),
  "component---src-templates-document-js": () => import("./../../../src/templates/document.js" /* webpackChunkName: "component---src-templates-document-js" */),
  "component---src-templates-order-js": () => import("./../../../src/templates/order.js" /* webpackChunkName: "component---src-templates-order-js" */)
}

